import axios from 'axios';
import { getToken, isAuthenticated } from './auth'

// const baseURL = 'https://appvendasapi.rkmengenharia.com.br/pt';
// const baseURL = 'https://appvendasapi1.rkmengenharia.com.br/pt';
const baseURL = 'https://appvendas-api-homolog.rkmengenharia.com.br/pt';


const api = axios.create({ baseURL });

api.interceptors.request.use(async(options) => {
    const authenticated = await isAuthenticated();
    if (authenticated) {
        options.headers['x-access-token'] = await getToken()
    }
    return options;
})

api.interceptors.response.use(response => {
    return response.data;
})

api.baseUrl = baseURL;

export default api;